<template>
	<div class="home mm">

		<div class="android">
			<div class="group-wrapper">
				<img class="group" alt="Group" src="@/assets/group-13.png" />
			</div>
		</div>

	</div>
</template>

<script>
import "@/css/mobile.scss";

export default {
	name: "mobile",
	data() {
		return {
			pageName: "home",
		};
	},

	metaInfo() {
		return {
			meta: [
				{
					name: "viewport",
					content:
						"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
				},
			],
		};
	},
};
</script>
